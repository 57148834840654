import EmptyData from 'components/commons/EmptyData';
import ShowNotFound from 'components/commons/ShowNotFound';
import { StudentRank } from 'models/class.model';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetClassesRankingQuery } from 'services/class.service';
import styled from 'styled-components';
import OtherRank from './OtherRank';
import TopRank from './TopRank';

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ddd;
`;

const RankHeader = styled.div`
  background-color: #0275d8;
  text-align: center;
  height: 80px;
  line-height: 80px;
  h3 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0;
  }
`;

const RankPage = (): ReactElement => {
  const { classroom: cls } = useParams<{ classroom: string }>();

  const { data, isError, error } = useGetClassesRankingQuery(cls);

  const students = data ?? ([] as StudentRank[]);

  if (isError) {
    return <ShowNotFound error={error} />;
  }

  if (students.length < 3) {
    return <EmptyData title="Chưa đủ học viên để đánh giá." />;
  } else {
    const topStudents = students.filter((student) => student.rank <= 3);
    const otherStudents = students.filter((student) => student.rank > 3);

    return (
      <Wrapper>
        <RankHeader>
          <h3>Bảng xếp hạng</h3>
        </RankHeader>

        <TopRank students={topStudents} />
        {otherStudents.length > 0 &&
          otherStudents.map((student, index) => <OtherRank key={index} student={student} />)}
      </Wrapper>
    );
  }
};

export default RankPage;
