import styled from 'styled-components';
import Main from 'components/commons/Main';

const ClassMain = styled(Main)`
  & {
    height: calc(100vh - 178px);
    overflow-y: scroll;
  }
`;

export default ClassMain;
