import NotAuthorized from 'components/commons/NotAuthorized';
import ShowNotFound from 'components/commons/ShowNotFound';
import MediaQuery from 'layouts/layout-components/MediaQuery';
import Drawer from 'layouts/learning/drawer/Drawer';
import LoadingSpin from 'libraries/components/LoadingSpin';
import { Lesson } from 'models/class.model';
import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Route, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { learning } from 'routers/routeNames';
import { useGetLectureCommentsQuery } from 'services/comment.service';
import {
  savePreviousLectures,
  useGetLecturesByClassIdQuery,
  useGetLecturesContentQuery,
} from 'services/onlinecourse.service';
import { selectIsSidebarCollapse } from 'store/general.slice';
import styled from 'styled-components';
import useMedia from 'utils/useMedia';
import LectureContent from './LectureContent';
import LessonsMenu from './menu/LessonsMenu';
import LecturePageContainer from './style/LecturePageContainer';

const ContentContainer = styled.div`
  position: relative;
  @media screen and (max-width: 991px) {
    position: static !important;
  }
  @media screen and (min-width: 992px) {
    height: 100vh;
    // overflow-y: scroll;
  }
`;

const getTotalLessons = (lessons: Lesson[]) => {
  return lessons.reduce((total, item) => {
    total += item.lectures.filter(({ type }) => type === 'lecture').length;
    return total;
  }, 0);
};

const LectureContentPage = (): ReactElement => {
  const { xs, sm, md } = useMedia();
  const isSidebarCollapse = useSelector(selectIsSidebarCollapse);

  const { classId, lessonId, lectureId } = useParams<{
    classId: string;
    lessonId: string;
    lectureId: string;
  }>();

  const { data: course, isLoading } = useGetLecturesByClassIdQuery(classId);
  useGetLectureCommentsQuery({ classId, lectureId });

  const lessons = course?.lessons ?? ([] as Lesson[]);

  const totalLessons = getTotalLessons(lessons);

  let prevLessonId = lessonId,
    nextLessonId = lessonId,
    prevLectureId = '',
    nextLectureId = '';

  const currentLessonIndex = lessons.findIndex((lesson) => lesson.id === lessonId);

  const { data, isFetching, error, isError } = useGetLecturesContentQuery({
    id: classId,
    lessonId: lessonId,
    lectureId: lectureId,
  });

  // Tạo link cho nút prev lecture, next lecture

  if (currentLessonIndex !== -1 && data?.type !== 'assignment') {
    const currentLectures = lessons[currentLessonIndex].lectures;
    const currentLectureIndex = currentLectures.findIndex((lecture) => lecture.id === lectureId);

    // Nếu không phải lesson đầu tiên
    if (currentLessonIndex !== 0) {
      // Nếu là lecture đầu của lesson thì chuyển qua lecture cuối của lesson trước
      if (currentLectureIndex === 0) {
        const prevLesson = lessons[currentLessonIndex - 1];

        prevLessonId = prevLesson.id;
        //TODO: Hiện gặp bug khi đang quá trình test, remove lecture liên tục nên lỗi khi lấy previous lecture không chính xác
        prevLectureId =
          prevLesson.lectures.length > 0
            ? prevLesson.lectures[prevLesson.lectures.length - 1]?.id
            : '';
      } else {
        prevLectureId = currentLectures[currentLectureIndex - 1]?.id;
      }
    } else {
      // Nếu không phải lecture đầu tiên
      if (currentLectureIndex > 0) {
        prevLectureId = currentLectures[currentLectureIndex - 1].id;
      }
    }

    // Nếu không phải lesson cuối cùng
    if (currentLessonIndex < lessons.length - 1) {
      // Nếu là lecture cuối của lesson thì chuyển qua lecture đầu của lesson kế tiếp
      if (currentLectureIndex === currentLectures.length - 1) {
        const nextLesson = lessons[currentLessonIndex + 1];

        nextLessonId = nextLesson.id;
        nextLectureId = nextLesson.lectures.length > 0 ? nextLesson.lectures[0].id : '';
      } else {
        nextLectureId = currentLectures[currentLectureIndex + 1].id;
      }
    } else {
      // Nếu không phải lecture cuối cùng
      if (currentLectureIndex !== currentLectures.length - 1) {
        nextLectureId = currentLectures[currentLectureIndex + 1].id;
      }
    }
  }

  //Lưu lại đường dẫn bài đang học
  useEffect(() => {
    savePreviousLectures(classId, lessonId, lectureId);
  }, [lessonId, lectureId]);

  if (data?.type === 'assignment') {
    return (
      <>
        <Route
          exact
          path={`/learn/${classId}/${lessonId}/${lectureId}`}
          render={() => {
            window.location.href = `https://learn.techmaster.vn/lop-hoc/${classId}/bai-tap?id=${data?.exercise_id}`;
            return null;
          }}
        />
        {/* <Redirect to="https://techmaster.vn" /> */}
      </>
    );
  }
  if (error && 'status' in error) {
    const errorData = error.data as { error: string };
    if (error.status === 403) {
      return (
        <LecturePageContainer>
          <ContentContainer
          // style={{
          //   paddingTop: '66px',
          // }}
          >
            <NotAuthorized />
          </ContentContainer>
        </LecturePageContainer>
      );
    } else if (error.status === 500) {
      const link = JSON.parse(`${localStorage.getItem(classId)}`);
      let lectureLink = '';
      lectureLink = generatePath(learning.welcomePage, {
        classId: classId,
      });
      return (
        <Route
          exact
          path={`/learn/${classId}/${link.lessonId}/${link.lectureId}`}
          render={() => {
            window.location.href = lectureLink;
            return null;
          }}
        />
      );
    } else {
      if (error.data === 'Bạn là giảng viên chứ không phải học viên của lớp') {
        return (
          <>
            <Route
              exact
              path={`/learn/${classId}/${lessonId}/${lectureId}`}
              render={() => {
                window.location.href = `${process.env.REACT_APP_API_TEACHER}/preview/${classId}/${lessonId}/${lectureId}`;
                return null;
              }}
            />
            {/* <Redirect to="https://techmaster.vn" /> */}
          </>
        );
      } else {
        return (
          <LecturePageContainer>
            <ContentContainer
            // style={{
            //   paddingTop: '66px',
            // }}
            >
              <ShowNotFound error={error} />
            </ContentContainer>
          </LecturePageContainer>
        );
      }
    }
  }

  return (
    <LecturePageContainer>
      <ContentContainer
        style={{
          // paddingTop: '66px',
          marginRight: xs || sm || md ? 0 : isSidebarCollapse ? 0 : 320,
        }}
      >
        {isFetching && <LoadingSpin text="Đang lấy nội dung bài giảng ..." />}

        <CSSTransition in={!isFetching} classNames="fade" timeout={300}>
          <>
            {!isFetching && (
              <LectureContent
                prevLessonId={prevLessonId}
                nextLessonId={nextLessonId}
                prevLectureId={prevLectureId}
                nextLectureId={nextLectureId}
                classId={classId}
              />
            )}
          </>
        </CSSTransition>
      </ContentContainer>

      <MediaQuery on={['lg', 'xl', 'xxl']}>
        <LessonsMenu
          totalLessons={totalLessons}
          currentLesson={lessonId}
          currentLecture={lectureId}
          isLoading={isLoading}
        />
      </MediaQuery>

      <MediaQuery on={['xs', 'sm', 'md']}>
        <Drawer>
          <LessonsMenu
            totalLessons={totalLessons}
            currentLesson={lessonId}
            currentLecture={lectureId}
          />
        </Drawer>
      </MediaQuery>
    </LecturePageContainer>
  );
};

export default LectureContentPage;
